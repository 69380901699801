<template>
  <v-card>
    <v-card-title>Ralizado (Nº de Vendas)</v-card-title>
    <highcharts :options="chartOptions"></highcharts>
  </v-card>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  name: "CardRealizado",

  props: {
    resumoVendas: {
      type: Array
    }
  },

  components: {
    highcharts: Chart
  },

  data() {
    return {
      chartOptions: {
        title: {
          text: ""
        },

        chart: {
          height: 350,
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              style: {
                fontWeight: "500"
              }
            },
            startAngle: 0,
            endAngle: 360,
            size: "90%"
          }
        },
        series: [
          // {
          //   type: "pie",
          //   innerSize: "50%",
          //   data: []
          // }
        ],
        lang: {
          noData: "Não há dados para serem apresentados"
        },
        noData: {
          style: {
            fontWeight: "normal",
            fontSize: "1rem",
            color: "rgba(0, 0, 0, 0.6)"
          }
        },

        credits: {
          enabled: false
        }
      }
    };
  },

  watch: {
    resumoVendas() {
      this.init();
    }
  },

  methods: {
    async init() {
      let obj = this.groupByVendas(this.resumoVendas);

      this.chartOptions.series = {
        type: "pie",
        innerSize: "50%",
        name: "QTD",
        data: Object.keys(obj).map(function(key) {
          return [key, obj[key]];
        })
      };
    },

    groupByVendas(array) {
      return array.reduce((result, currentValue) => {
        if (currentValue.novo_usado == "N") {
          result["novos"] =
            result["novos"] + parseInt(currentValue.qtde) ||
            parseInt(currentValue.qtde);
        }

        if (currentValue.novo_usado == "U") {
          result["usados"] =
            result["usados"] + parseInt(currentValue.qtde) ||
            parseInt(currentValue.qtde);
        }

        if (currentValue.status == "P1") {
          result["consorcio"] =
            result["consorcio"] + parseInt(currentValue.qtde) ||
            parseInt(currentValue.qtde);
        }
        return result;
      }, {});
    }
  },

  mounted() {
    this.init();
  }
};
</script>

<style lang="scss"></style>
